import React, { useState } from "react";
import * as pdfjs from "pdfjs-dist/build/pdf";
import * as pdfjsworker from "pdfjs-dist/build/pdf.worker";

import { getDocument } from "pdfjs-dist";

import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

import "./Converter.css";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsworker;

const BACKEND_URL = "https://lectio-backend-734t6.ondigitalocean.app";

const Converter = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [convertedFile, setConvertedFile] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handlePageNumberChange = (e) => {
    setPageNumber(parseInt(e.target.value));
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refresh_token");

    try {
      const response = await fetch(
        BACKEND_URL + "/accounts/refresh-token/",
        {
          method: "POST",
          body: JSON.stringify({ refresh: refreshToken }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("access_token", data.access);
        return data.access;
      } else {
        throw new Error("Failed to refresh token");
      }
    } catch (error) {
      throw error;
    }
  };

  const handleConvert = async () => {
    if (!selectedFile) {
      console.log("No file selected");
      return;
    }

    setIsLoading(true);
    setConvertedFile(null);
    setErrorMessage("");

    const fileReader = new FileReader();

    fileReader.onload = async () => {
      const typedArray = new Uint8Array(fileReader.result);
      const pdf = await getDocument({ data: typedArray }).promise;
      const page = await pdf.getPage(pageNumber);

      const scale = 2;
      const viewport = await page.getViewport({ scale: scale });

      const canvas = document.createElement("canvas");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const canvasContext = canvas.getContext("2d");
      await page.render({
        canvasContext,
        viewport: viewport,
      }).promise;

      canvas.toBlob(
        async (blob) => {
          const formData = new FormData();
          formData.append("file", blob, "image.jpg");

          try {
            let accessToken = localStorage.getItem("access_token");

            let response = await fetch(
              BACKEND_URL + "/api/claude/",
              {
                method: "POST",
                body: formData,
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );

            if (response.status === 401) {
              // Access token expired, refresh the token
              try {
                accessToken = await refreshToken();
                response = await fetch(
                  BACKEND_URL + "/api/claude/",
                  {
                    method: "POST",
                    body: formData,
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                    },
                  }
                );
              } catch (error) {
                setErrorMessage(
                  "Failed to refresh token. Please refresh this page or log in again."
                );
                setIsLoading(false);
                return;
              }
            }

            if (response.ok) {
              const blob = await response.blob();
              const url = window.URL.createObjectURL(blob);
              setConvertedFile(url);
            } else {
              setErrorMessage(
                "Error sending image to backend. Please try again or contact support."
              );
            }
          } catch (error) {
            setErrorMessage("An error occurred. More information: " + error);
          }
          setIsLoading(false);
        },
        "image/jpeg",
        1.0
      );
    };
    fileReader.readAsArrayBuffer(selectedFile);
  };

  return (
    <div className="converter">
      <h2>Financial Statement PDF to Excel Converter</h2>
      <div className="converter-container">
        <input type="file" accept=".pdf" onChange={handleFileChange} />
        <div className="page-number-container">
          <label htmlFor="pageNumber">Page Number:</label>
          <input
            type="number"
            id="pageNumber"
            min="1"
            value={pageNumber}
            onChange={handlePageNumberChange}
          />
        </div>
        <button onClick={handleConvert} disabled={isLoading}>
          {isLoading ? (
            <>
              Converting <LoadingIndicator />
            </>
          ) : (
            "Convert to Excel"
          )}
        </button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {convertedFile && !errorMessage && (
          <div className="download-link">
            <a href={convertedFile} download="financial-stmt.xlsx">
              Download Excel File
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Converter;
