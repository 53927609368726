import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="footer-content">
        <div className="footer-section">
          <h3>About Lectio</h3>
          <p>The modern research assistant for financial analysts.</p>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p> <a href="mailto:pj@getlectio.com">pj@getlectio.com</a></p>
        </div>
        <div className="footer-section">
          <h3>Follow Us</h3>
          <ul className="social-links">
            <li><a href="https://facebook.com/lectio">Facebook</a></li>
            <li><a href="https://twitter.com/lectio">Twitter</a></li>
            <li><a href="https://linkedin.com/company/lectio">LinkedIn</a></li>
          </ul>
        </div>
      </div> */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Lectio. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;