import React from "react";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="home-page">
      <section>
        <h1>There's a dinosaur in the room.</h1>
      </section>

      <section>
        <p>
          And there's software for financial analysts. But wait, I repeated
          myself.
        </p>
        <p>
          For far too long, financial analysts have been stuck with legacy
          software that feels like it belongs in the past. FactSet, founded in
          1978, and Bloomberg, the go-to choice for Boomers, have dominated the
          market with their outdated interfaces and cumbersome workflows.
        </p>
        <p>
          It's time for a change. It's time for <strong>Lectio</strong>.
        </p>
      </section>

      <section>
        <p>
          At Lectio, we believe that financial analysis software should be
          modern, intuitive, and dare we say, even fun to use. We're on a
          mission to build a cutting-edge research assistant that empowers
          analysts to work smarter, not harder.
        </p>
        <p>
          Our first feature is a game-changer:{" "}
          <strong>
            automated conversion of financial statements from PDF to Excel
            format
          </strong>
          . No more tedious copying and pasting. No more wasted hours on manual
          data entry. With Lectio, you'll have more time to focus on what really
          matters – uncovering insights and making informed decisions.
        </p>
      </section>

      <section>
        <p>
          But we're just getting started. We have big plans for Lectio, and we
          want you to be a part of shaping its future.
        </p>
        <ul>
          <li>
            Are you tired of clunky interfaces that make your work harder than
            it needs to be?
          </li>
          <li>
            Do you find yourself wishing for features that would streamline your
            analysis process?
          </li>
          <li>Have you ever thought, "There has to be a better way"?</li>
        </ul>
      </section>

      <section>
        <p>
          <a href="mailto:pj@getlectio.com">We want to hear from you.</a> Share
          your pain points, your wishlist, and your wildest dreams for what
          financial analysis software could be. We're here to listen and build
          the tools you need to succeed.
        </p>

        <p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfq-MFP-eTdBhyEt6sgI8sZVhu_keZ7G5o4FplGDF-V0Y3Kgg/viewform?usp=sf_link">
            Drop your email to gain early access.
          </a>
        </p>
        <p>It's time that we let the dinosaurs become fossil records.</p>
        <p>
          It's time that you actually like your tools as a financial analyst.
        </p>
        <p>
          <strong>It's time for Lectio.</strong>
        </p>
      </section>
    </div>
  );
};

export default HomePage;
