import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import FAQPage from "./pages/FAQPage/FAQPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import Converter from "./pages/Converter/Converter";
import HomePage from "./pages/HomePage/HomePage";
import FeaturesPage from "./pages/Features/FeaturesPage";

const App = () => {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    return accessToken !== null && refreshToken !== null;
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (!isAuthenticated() && currentPath === "/converter") {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="app">
      <Header isAuthenticated={isAuthenticated} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/features" element={<FeaturesPage />} />
        <Route
          path="/login"
          element={
            isAuthenticated() ? (
              <Navigate to="/" replace />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route
          path="/converter"
          element={
            isAuthenticated() ? (
              <Converter />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="/faq" element={<FAQPage />} />
        {/* TODO: Add more routes for other pages */}
      </Routes>
      <Footer />
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;