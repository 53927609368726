import React from "react";
import "./FAQPage.css";

const FAQPage = () => {
  return (
    <div className="faq-page">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-item">
        <h2>
          Is the privacy of the financial statements uploaded to Lectio protected?
        </h2>
        <p>
          Yes, the privacy and security of your financial statements are of
          utmost importance to us. We employ industry-standard security measures
          to protect your data:
        </p>
        <ul>
          <li>All uploaded files are encrypted in transit.</li>
          <li>
            We do not share or use your financial data for any purposes other
            than generating the Excel output.
          </li>
          <li>
            Once the conversion process is complete and the Excel file is
            generated, we securely delete the uploaded PDF from our servers.
          </li>
        </ul>
        <p>
          Rest assured that your financial statements remain confidential and
          are used solely for the purpose of converting them to Excel format.
        </p>
        <p>
          If you have any concerns about the privacy and security of your data,
          or need any certification so that your management approves the use of Lectio,
          please contact me at <a href="mailto:pj@getlectio.com">pj@getlectio.com</a>.
        </p>
      </div>
      <div className="faq-item">
        <h2>How accurate is the conversion from PDF to Excel?</h2>
        <p>
          Our software intelligently analyzes the structure of the financial
          statement PDF and generates a corresponding Excel file. In most cases,
          the table structure and data are accurately reproduced. However, due
          to the complexity of some PDFs, there may be occasional formatting
          errors, such as misaligned cells. These issues are usually minor and
          easily correctable with minimal effort.
        </p>
        <p>
          Based on feedback from our users, the numerical data is typically
          transferred correctly. Nevertheless, we recommend conducting a quick
          sanity check to ensure the accuracy of the converted information.
        </p>
        <p>
          By using our tool, you can save up to 80% of the time previously spent
          on manually copying and pasting data from PDFs to Excel. This allows
          you to focus on analyzing the financial data rather than wasting time
          on tedious data entry tasks.
        </p>
      </div>
      <div className="faq-item">
        <h2>I have more questions! How can I contact you?</h2>
        <p>
          Please feel free to contact me if you have any questions or feedback!
          You can reach me at{" "}
          <a href="mailto:pj@getlectio.com">pj@getlectio.com</a>.
        </p>
      </div>
    </div>
  );
};

export default FAQPage;
