import React from "react";
import "./FeaturesPage.css";

const FeaturesPage = () => {
  return (
    <div className="features-page">
      <h1>Welcome to Lectio - Your Modern Research Assistant</h1>
      <p>
        At Lectio, we believe that financial analysis software should be modern,
        intuitive, and dare we say, even fun to use. We're on a mission to build
        a cutting-edge research assistant that empowers analysts to work
        smarter, not harder.
      </p>

      <h2>Our First Feature: Automatic Conversion of Financial Statements</h2>
      <p>
        We understand that every financial analyst hates the annoying task of
        copying and pasting numbers from PDFs to Excel. That's why we've built
        a tool to help you save hours each week, so you can focus on what you
        love - exercising your judgment in evaluating companies.
      </p>
      <p>
        With Lectio, you can automatically convert financial statements from PDF
        to Excel format. No more manual data entry or wasted time. Just upload
        your PDF, and let the software do the rest.
      </p>

      <br/>

      <div style={{ position: "relative", paddingBottom: "62.5%", height: 0 }}>
        <iframe
          src="https://www.loom.com/embed/b7f46213953a475aa20df74c9fbddd18?sid=ee2165aa-85b7-47ca-b30b-839fba5e5842"
          title="Loom video player"
          frameBorder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        ></iframe>
      </div>

      <br/>

      <h2>We Want to Hear from You</h2>
      <p>
        It's time that we make financial analysis software great again.
        If you have any feature requests or suggestions, please don't
        hesitate to reach out to me. You can email me directly at{" "}
        <a href="mailto:pj@getlectio.com">pj@getlectio.com</a>, or fill out our{" "}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfq-MFP-eTdBhyEt6sgI8sZVhu_keZ7G5o4FplGDF-V0Y3Kgg/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          feature request form
        </a>
        .
      </p>

      <p>
        Thank you for choosing Lectio. We're confident that it'll be an awesome
        experience for you. 
      </p>
    </div>
  );
};

export default FeaturesPage;
