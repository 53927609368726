import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = ({ isAuthenticated }) => {
  return (
    <header className="header">
      <div className="logo"><a href="/">Lectio</a></div>
      <nav className="navigation">
        <ul>
          {/* <li><a href="/about">About</a></li> */}
          {/* <li><a href="/contact">Contact</a></li> */}
          <li><a href="/features">Features</a></li>
          <li><a href="/faq">FAQ</a></li>
        </ul>
      </nav>
      <div className="auth-buttons">
        {isAuthenticated() ? (
          <div className="user-tools">
            <button className="user-tools-toggle">User Tools</button>
            <ul className="user-tools-dropdown">
              <li><a href="/converter">PDF to Excel Converter</a></li>
              {/* Add more user-specific links */}
            </ul>
          </div>
        ) : (
          <Link to="/login">
            <button className="login-button">Log In</button>
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;